import { AxiosInstance } from 'axios';
import apiOf, { downloadFile, extractData } from '../shorthand';
import Sanas from '../types/sanas';

const sanas = (apiBase: AxiosInstance) => {
  const controller = 'sanas';
  return {
    delete: apiOf.deleteBy<Sanas.Delete.Response>(apiBase, (id) => `${controller}/${id}`),
    getAll: apiOf.getByQueryString<Sanas.GetAll.Request, Sanas.GetAll.Response>(apiBase, `${controller}/all`),
    upsert: apiOf.put<Sanas.Upsert.Request, Sanas.Upsert.Response>(apiBase, `${controller}`, { headers: { 'Content-Type': 'multipart/form-data' } }),
    getById: apiOf.getBy<Sanas.Get.Response>(apiBase, (id) => `${controller}/${id}`),
    allIndexed: apiOf.get<Sanas.GetAllIndexed.Response>(apiBase, `${controller}/all-indexed`),
    accreditationDocument: (request: Sanas.DownloadAccreditation.Request) => downloadFile(apiBase.get<Sanas.DownloadAccreditation.Response>(`${controller}/accreditation-document/${encodeURIComponent(request)}`, { responseType: 'blob' })),
    setImage: (request: Sanas.ImageUpload.Request) => extractData(apiBase.post<Sanas.ImageUpload.Response>(`${controller}/image/${request.inspectionBodyId}`, request.file, { headers: { 'Content-Type': request.contentType } })),
  };
};

export default sanas;
