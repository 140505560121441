import { AxiosInstance } from 'axios';
import apiOf, { extractData } from '../../shorthand';
import EntityTemplate from '../../types/entity-management/entity-template';

const entityTemplate = (apiBase: AxiosInstance) => {
  const controller = 'entity-management/entity-template';
  return {
    getEntityTemplates: apiOf.get<EntityTemplate.GetEntityTemplates.Response>(apiBase, `${controller}`),
    putTemplate: apiOf.put<EntityTemplate.PostTemplate.Request, EntityTemplate.PostTemplate.Response>(apiBase, `${controller}`),
    history: apiOf.get<EntityTemplate.ExecutionHistory.Response>(apiBase, `${controller}/history`),
    execute: (request: EntityTemplate.ExecuteTemplate.Request) => 
      extractData(apiBase.post<EntityTemplate.ExecuteTemplate.Response>(`${controller}/execute?fileName=${encodeURIComponent(request.fileName)}&templateId=${encodeURIComponent(request.templateId)}`, request.file, { headers: { 'Content-Type': request.type }, timeout: 600000 })),
  };
};

export default entityTemplate;
