import React from 'react';
import jwt_decode from 'jwt-decode';
import { useParams, useSearchParams } from 'react-router-dom';
import { refreshApiBase, refreshApi } from '@sanedi.epc/api';
import AuthTokenPayload from '@sanedi.epc/types/auth/auth-token-payload';
import Role from '@sanedi.epc/types/enums/role';

interface AuthorizePageProps {
  onAuth?: (token: string) => void;
  isAdmin?: boolean;
}

const AuthorizePage = ({ onAuth, isAdmin }: AuthorizePageProps) => {
  const [searchParams, _setSearchParams] = useSearchParams();
  const { authToken } = useParams<'authToken'>();
  onAuth?.(authToken!);
  localStorage.setItem('token', authToken!);
  refreshApiBase(authToken);
  refreshApi();

  const redirectQuery = searchParams.get('redirectUrl');
  if (redirectQuery) {
    window.location.pathname = redirectQuery;
  } else {
    if (isAdmin)
      window.location.pathname = '/';
    else {
      if (authToken) {
        const tokenPayload = jwt_decode(authToken) as AuthTokenPayload;
        if (tokenPayload.role === Role.RegisteredProfessional) {
          window.location.pathname = '/client/professional-application';
        } else {
          window.location.pathname = '/client/list-buildings';
        }
      }
      else {
        window.location.pathname = '/client/list-buildings';
      }
    }
  }
  return null;
};

export default AuthorizePage;