import { AxiosInstance } from 'axios';
import apiOf, { downloadFile } from '../shorthand';
import Certificate from '../types/certificate';

const certificates = (apiBase: AxiosInstance) => {
  const controller = 'certificate';
  return {
    generateCertificate: apiOf.getBy<Certificate.Generate.Response>(apiBase, (energyInformationId) => `${controller}/generate/${energyInformationId}`),
    generateDraft: (request: Certificate.GenerateDraft.Request) => 
      downloadFile(apiBase.get<Certificate.GenerateDraft.Response>(`${controller}/generate/draft/${encodeURIComponent(request)}`, { responseType: 'blob' })),
  };
};

export default certificates;
