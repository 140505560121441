import { AxiosInstance, AxiosRequestConfig } from 'axios';
import join from 'lodash/join';
import map from 'lodash/map';

export const extractData = async <T>(response: Promise<{ data: T }>) => (await response).data;
export const downloadFile = async (asyncResponse: Promise<{ data: Blob; headers: { [name: string]: string } }>) => asyncResponse.then(({ data, headers }) => {
  var disposition = headers['content-disposition'];
  var filename = 'file';
  var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
  var matches = filenameRegex.exec(disposition);
  if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');

  // create file link in browser's memory
  const href = URL.createObjectURL(data);

  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename); //TODO: Find and extract fileName
  document.body.appendChild(link);
  link.click();

  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
});

export const objectToQueryStringParams = (obj: any, prefix?: string): string => join(map(obj, (value, key) => typeof value === 'object' ? objectToQueryStringParams(value, prefix ? `${prefix}.${key}` : key) : `${prefix ? prefix + '.' : ''}${encodeURIComponent(key)}=${encodeURIComponent(value ?? '')}`), '&');
export const objectToQueryString = (obj: any, prefix?: string): string => `?${objectToQueryStringParams(obj, prefix)}`;

const apiOf = {
  get: <TResponse>(apiBase: AxiosInstance, url: string) => () => extractData(apiBase.get<TResponse>(url)),
  getBy: <TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => () => extractData(apiBase.get<TResponse>(url(by))),
  getByQueryString: <TRequest, TResponse>(apiBase: AxiosInstance, url: string) => (queryStringifyMe: TRequest) => () => extractData(apiBase.get<TResponse>(url + objectToQueryString(queryStringifyMe))),
  getByIdAndQueryString: <TRequest, TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => (queryStringifyMe: TRequest) => () => extractData(apiBase.get<TResponse>(url(by) + objectToQueryString(queryStringifyMe))),

  delete: <TResponse>(apiBase: AxiosInstance, url: string) => () => extractData(apiBase.delete<TResponse>(url)),
  deleteBy: <TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => extractData(apiBase.delete<TResponse>(url(by))),

  // getById: <TResponse>(apiBase: AxiosInstance, url: (id: string) => string) => (id: string) => () => extractData(apiBase.get<TResponse>(url(id))),
  post: <TRequest, TResponse>(apiBase: AxiosInstance, url: string) => (request: TRequest) => extractData(apiBase.post<TResponse>(url, request)),
  postBy: <TRequest, TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => (request: TRequest) => extractData(apiBase.post<TResponse>(url(by), request)),
  postQuery: <TRequest, TResponse>(apiBase: AxiosInstance, url: string) => (request: TRequest) => () => extractData(apiBase.post<TResponse>(url, request)),

  patch: <TRequest, TResponse>(apiBase: AxiosInstance, url: string) => (request: TRequest) => extractData(apiBase.patch<TResponse>(url, request)),
  patchBy: <TRequest, TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => (request: TRequest) => extractData(apiBase.patch<TResponse>(url(by), request)),

  put: <TRequest, TResponse>(apiBase: AxiosInstance, url: string, config?: AxiosRequestConfig) => (request: TRequest) => extractData(apiBase.put<TResponse>(url, request, config)),
  putBy: <TRequest, TResponse, TBy = string>(apiBase: AxiosInstance, url: (by: TBy) => string) => (by: TBy) => (request: TRequest) => extractData(apiBase.put<TResponse>(url(by), request)),
    
};

export default apiOf;
