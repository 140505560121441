import { AxiosInstance } from 'axios';
import apiOf from '../shorthand';
import SignOffs from '../types/signOffs';

const signOffs = (apiBase: AxiosInstance) => {
  const controller = 'sign-off';
  return {
    possibleStateTransitions: apiOf.getBy<SignOffs.TransitionStates.Response>(apiBase, (id) => `${controller}/${id}/entity-states`),
    signOffEntity: apiOf.postBy<SignOffs.SignOff.Request, SignOffs.SignOff.Response, SignOffs.SignOff.By>(apiBase, ({ entityId, entityName }) => `${controller}/${entityName}/${entityId}`),
    lastTransition: apiOf.getBy<SignOffs.LastTransition.Response, SignOffs.LastTransition.By>(apiBase, ({ entityId, entityName }) => `${controller}/${entityName}/${entityId}/last-transition`),
    notes: apiOf.getBy<SignOffs.Notes.Response, SignOffs.Notes.By>(apiBase, ({ entityId, entityName }) => `${controller}/${entityName}/${entityId}/notes`),
    auditTrails: apiOf.getBy<SignOffs.AuditTrail.Response, SignOffs.AuditTrail.By>(apiBase, ({ entityId, entityName }) => `${controller}/${entityName}/${entityId}/audit-trails`),
    requiredDocuments: apiOf.getBy<SignOffs.RequiredDocuments.Response>(apiBase, (entityName) => `${controller}/${entityName}/required-documents`)
  };
};

export default signOffs;