import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const retryDelay = (failureCount: number, _error: any) => {
  switch (failureCount) {
    case 1:
      return 500; // 500 ms
    case 2:
      return 1000 * 10; // 10 sec
    case 3:
      return 1000 * 60 * 1; // 1 min
    default:
      return 1000 * 60 * 5; // 5 min
  }
};

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      // cacheTime: Infinity,
      // retry,
      retry: false
    },
    queries: {
      cacheTime: 1000 * 60 * 60, // 1 hour
      staleTime: 1000 * 60 * 5, // 5 min
      // refetchOnWindowFocus: false,
      // retry,
      retryDelay,
      keepPreviousData: true
    }
  }
});

const withQueryClientProvider = function <P, C extends ((props?: P) => JSX.Element) | React.MemoExoticComponent<(props?: P) => JSX.Element>>(WrappedComponent: C) {
  return (props?: P) => (
    <QueryClientProvider client={queryClient} contextSharing>
      <WrappedComponent {...(props as any)} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default withQueryClientProvider;