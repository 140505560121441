import { AxiosInstance } from 'axios';
import apiOf, { extractData } from '../shorthand';
import EnergyInfo from '../types/energy-information';

const energyInformation = (apiBase: AxiosInstance) => {
  const controller = 'energy-info';
  return {
    calculate: apiOf.post<EnergyInfo.Calculate.Request, EnergyInfo.Calculate.Response>(apiBase, `${controller}/calculate`),
    upsert: apiOf.putBy<EnergyInfo.Upsert.Request, EnergyInfo.Upsert.Response, boolean>(apiBase, (isAmendment) => isAmendment ? `${controller}/amendment` : `${controller}`),
    renewEpc: apiOf.getBy<EnergyInfo.Renew.Response>(apiBase, (id) => `${controller}/renew/${id}`),
    get: apiOf.getBy<EnergyInfo.Get.Response>(apiBase, (id) => `${controller}/${id}`),
    getHistory: apiOf.getByIdAndQueryString<EnergyInfo.GetHistory.Request, EnergyInfo.GetHistory.Response>(apiBase, (buildingId) => `${controller}/history/${buildingId}`),
    bulkUpload: (request: EnergyInfo.BulkUpload.Request) =>
      extractData(apiBase.post<EnergyInfo.BulkUpload.Response>(`${controller}/bulk-upload?fileName=${encodeURIComponent(request.fileName)}`, request.file, { headers: { 'Content-Type': request.type } })),
  };
};

export default energyInformation;