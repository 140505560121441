import React from 'react';
import { Alert } from '@mui/material';
import { SnackbarContent, CustomContentProps } from 'notistack';

interface CustomAlertProps extends CustomContentProps {
}

const CustomAlert = React.memo(
  React.forwardRef<HTMLDivElement, CustomContentProps>(
    ({ message, variant }: React.PropsWithChildren<CustomAlertProps>, forwardRef) => {

      return (
        <SnackbarContent ref={forwardRef}>
          <Alert severity={variant as any} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </SnackbarContent>
      );
    }
  )
);

export default CustomAlert;