import { AxiosInstance } from 'axios';
import apiOf from '../shorthand';
import Chat from '../types/chat';

const chat = (apiBase: AxiosInstance) => {
  const controller = 'chat';
  return {
    send: apiOf.post<Chat.Send.Request, Chat.Send.Response>(apiBase, `${controller}/send`),
  };
};

export default chat;
