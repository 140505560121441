/* eslint-disable no-console */
import React from 'react';
import AuthContext from './auth-context';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';

const RedirectToLogin = () => window.location.href = `${process.env.REACT_APP_LOGIN_URL!}${window.location.pathname !== '/' ? `?redirectUrl=${encodeURIComponent(window.location.pathname)}` : ''}`;

var logoutTimer: any;
var currentTimerExp: number = 0;

const logoutOnTokenExpire = (token: string) => {
  const { exp }: { exp: number } = jwt_decode(token);

  if (currentTimerExp === exp)
    return;

  currentTimerExp = exp;

  const timeTillExpiration = dayjs('1970-01-01 00:00:00', { utc: true }).add(exp, 'seconds').diff(dayjs(undefined, { utc: true }), 'milliseconds');

  if (timeTillExpiration <= 0) {
    RedirectToLogin();
    return;
  }

  if (logoutTimer)
    clearTimeout(logoutTimer);

  logoutTimer = setTimeout(RedirectToLogin, timeTillExpiration);
};

const AuthValidation = (props: React.PropsWithChildren) => {
  const [token] = React.useContext(AuthContext);

  if (!token) {
    console.debug('No token');
    RedirectToLogin();
    return null;
  }

  const tokenDetails: any = React.useMemo(() => jwt_decode(token), [token]); // eslint-disable-line react-hooks/rules-of-hooks
  if (!dayjs('1970-01-01 00:00:00', { utc: true }).add(tokenDetails.exp, 'seconds').isAfter(dayjs(undefined, { utc: true }))) {
    console.debug('Expiration bad', dayjs('1970-01-01 00:00:00', { utc: true }).add(tokenDetails.exp, 'seconds').format(), '>', dayjs(undefined, { utc: true }).format());
    RedirectToLogin();
    return null;
  }

  //TODO: Server Side Validate Token?
  logoutOnTokenExpire(token);

  return <>{props.children}</>;
};

export default AuthValidation;