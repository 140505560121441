import React from 'react';
import { Box, CssBaseline, AppBar, Toolbar, Typography, Drawer, ListItemButton, ListItemIcon, ListItemText, Divider, useTheme, useMediaQuery, Stack } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ThemeFonts } from '@sanedi.epc/theme';
import AuthContext from '../common/auth-context';
import useAuthToken from '../hooks/use-auth-token';
import AuthTokenPayload from '@sanedi.epc/types/auth/auth-token-payload';

const drawerWidth = 240;
const smallDrawerWidth = 64;

interface SidebarLink {
  to: string;
  icon: React.ReactNode;
  label: string;
  hideIfNotAuthed?: boolean;
  hideIfAuthed?: boolean;
  hideIfPredicate?: (token?: AuthTokenPayload) => boolean;
  alignBottom?: boolean;
}

interface SidebarDivider { divider?: boolean; }

type SidebarEntry = SidebarLink | SidebarDivider;

const isDivider = (entry: SidebarEntry): entry is SidebarDivider => {
  if (Object.hasOwn(entry, 'divider'))
    return true;
  return false;
};

interface SidebarProps {
  heading: string | React.ReactNode;
  entries: SidebarEntry[];
}

const Sidebar = ({ heading, entries, children }: React.PropsWithChildren<SidebarProps>) => {
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  const token = useAuthToken();

  const alignedTop = React.useMemo(() => entries.filter(x => !Boolean((x as any).alignBottom)), [entries]);
  const alignedBottom = React.useMemo(() => entries.filter(x => Boolean((x as any).alignBottom)), [entries]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, borderRadius: 0, p: 0 }}>
        <Toolbar>
          {typeof heading == 'string' ? <Typography variant="h6" noWrap component="div">{heading}</Typography> : heading}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: isSmallDevice ? smallDrawerWidth : drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: isSmallDevice ? smallDrawerWidth : drawerWidth },
        }}
      >
        <Toolbar />
        <Stack flex={1} height={'100%'} width={'100%'} direction={'column'}>
          {
            alignedTop.map(
              (entry, index) => isDivider(entry) ?
                (<Divider key={index} />)
                :
                (
                  (entry.hideIfNotAuthed && !token) || (entry.hideIfAuthed && !!token) || (entry.hideIfPredicate?.(token))
                    ? null :
                    <Stack
                      key={index}
                      direction={'row'}
                    >
                      <ListItemButton
                        role='link'
                        data-href={entry.to!}
                        LinkComponent={Link}
                        {...({ to: entry.to })}
                        onClick={(e) => {
                          e.preventDefault();
                          navigate(entry.to!);
                        }}>
                        <ListItemIcon>
                          {entry.icon}
                        </ListItemIcon>
                        <ListItemText primary={entry.label} primaryTypographyProps={{ fontFamily: ThemeFonts.Heading, fontWeight: 400 }} />
                      </ListItemButton>
                    </Stack>
                )
            )
          }
          <Stack flex={1}>&nbsp;</Stack>
          {
            alignedBottom.map(
              (entry, index) => !isDivider(entry) ?
                (
                  (entry.hideIfNotAuthed && !token) || (entry.hideIfAuthed && !!token) || (entry.hideIfPredicate?.(token))
                    ? null :
                    <Stack
                      key={index}
                      direction={'row'}
                    >
                      <ListItemButton
                        role='link'
                        data-href={entry.to!}
                        LinkComponent={Link}
                        {...({ to: entry.to })}
                        onClick={(e) => {
                          e.preventDefault();
                          if (entry.to?.startsWith('https://'))
                            window.location.href = entry.to;
                          else
                            navigate(entry.to!);

                        }}>
                        <ListItemIcon>
                          {entry.icon}
                        </ListItemIcon>
                        <ListItemText primary={entry.label} primaryTypographyProps={{ fontFamily: ThemeFonts.Heading, fontWeight: 400 }} />
                      </ListItemButton>
                    </Stack>
                )
                : null
            )
          }
        </Stack>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3, mt: '64px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Sidebar;