import { AxiosInstance } from 'axios';
import apiOf, { downloadFile, extractData } from '../shorthand';
import Document from '../types/document';

const documentController = (apiBase: AxiosInstance) => {
  const controller = '/common/files';
  return {
    getDocumentTypes: apiOf.get<Document.DocumentTypes.Response>(apiBase, `${controller}/document-types`),
    getEpcConfidenceScores: apiOf.getBy<Document.EpcConfidenceScore.Response>(apiBase, (energyInfoId) => `${controller}/epc-confidence-scores/${energyInfoId}`),
    getLinkedFileSummaries: apiOf.getBy<Document.GetDocumentSummaries.Response>(apiBase, (id) => `${controller}/summaries/${id}`),
    delete: apiOf.deleteBy<Document.Delete.Response>(apiBase, (id) => `${controller}/${id}`),
    download: (request: Document.Download.Request) => downloadFile(apiBase.get<Document.Download.Response>(`${controller}/${encodeURIComponent(request)}`, { responseType: 'blob' })),
    postDocument: (request: Document.DocumentUpload.Request) =>
      extractData(apiBase.post<Document.DocumentUpload.Response>(`${controller}?fileName=${encodeURIComponent(request.fileName!)}&documentTypeId=${encodeURIComponent(request.documentTypeId!)}&linkedEntityId=${encodeURIComponent(request.linkedEntityId ?? '')}`, request.file, { headers: {
        'Content-Type': request.type
      } })),
  };
};

export default documentController;
