import { AxiosInstance } from 'axios';
import apiOf, { extractData } from '../shorthand';
import { PostDto, PostView } from '@sanedi.epc/types/common/post';
import PostTypes from '../types/post-types';

const postController = (apiBase: AxiosInstance) => {
  const controller = 'posts';
  return {
    upsert: apiOf.put<PostTypes.Submit.Request, PostTypes.Submit.Response>(apiBase, `${controller}`, { headers: { 'Content-Type': 'multipart/form-data' } }),
    getAll: apiOf.getByQueryString<PostTypes.All.Request, PostTypes.All.Response>(apiBase, `${controller}/all`),
    getViewAll: apiOf.getBy<PostTypes.ViewAll.Response, PostView>(apiBase, (by) => `${controller}/view-all/${by.postType}/${by.isFeatured}/${by.categoryId}`),
    getById: apiOf.getBy<PostTypes.Get.Response>(apiBase, (id) =>`${controller}/${id}`),
    delete: apiOf.deleteBy<string>(apiBase, (id) => `${controller}/delete/${id}`),
    getCategories: apiOf.get<PostTypes.Categories.Response>(apiBase, `${controller}/categories`),
    postImage: (request: PostDto) => extractData(apiBase.post<PostTypes.Submit.Response>(`${controller}/`, request.image, { headers: { 'Content-Type': 'image/*' } })),
  };
};

export default postController;