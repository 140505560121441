import type { RoleEnum } from '@sanedi.epc/types/enums/role';
import type FileUploadRequest from '@sanedi.epc/types/common/file-upload-request';
import type PaginatedRequest from '@sanedi.epc/types/common/paginated';
import type PaginatedResponse from '@sanedi.epc/types/common/paginated-response';
import type UserResponse from '@sanedi.epc/types/auth/user-response';
import type IVerifyHumanity from '@sanedi.epc/types/common/verify-humanity';
import Dictionary from '@sanedi.epc/types/common/dictionary';

interface UserInviteRequest {
  emailAddress: string;
  sanasBodyId?: string;
  role: RoleEnum;
}

interface LoginSuccessResponse {
  token: string;
  redirect: string;
}

interface LoginFailedResponse {
  message: string;
}

export const IsLoginSuccessResponse = (loginResponse: Auth.Login.Response): loginResponse is LoginSuccessResponse => Object.hasOwn(loginResponse, 'token') && Boolean((loginResponse as LoginSuccessResponse).token);

declare namespace Auth.Login {
  interface Request extends IVerifyHumanity {
    email: string;
    password: string;
  }
  type Response = LoginSuccessResponse | LoginFailedResponse;
}

declare namespace Auth.Register {
  interface Request extends IVerifyHumanity {
    firstName: string;
    surname: string;
    email: string;
    password: string;
    confirmPassword: string;
    isRegisteredProfessional?: boolean;
    registered
  }
  interface Response {
    token: string;
    redirect: string;
  }
}

declare namespace Auth.VerifyEmail {
  interface Request {
    token: string;
  }
    type Response = undefined;
}

declare namespace Auth.RequestReset {
  interface Request extends IVerifyHumanity {
    email: string;
  }
    type Response = undefined;
}

declare namespace Auth.ResetPassword {
  interface Request extends IVerifyHumanity {
    newPassword: string;
    token: string;
  }
    type Response = undefined;
}

declare namespace Auth.ChangePassword {
  interface Request {
    oldPassword: string;
    newPassword: string;
  }
    type Response = undefined;
}

declare namespace Auth.GetAllUsers {
    type Request = PaginatedRequest<RoleEnum | undefined>;
    type Response = PaginatedResponse<UserResponse[]>;
}

declare namespace Auth.DeactivateUser {
    type Request = undefined;
    type Response = string;
}

declare namespace Auth.BulkInvite {
    type Request = FileUploadRequest;
    type Response = undefined;
}

declare namespace Auth.Invite {
    type Request = UserInviteRequest;
    type Response = undefined;
}

declare namespace Auth.UserNamesByIds {
    type Request = string[];
    type Response = Dictionary<string>;
}

export default Auth;
