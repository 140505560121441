import values from 'lodash/values';
import invert from 'lodash/invert';

const Role = {
  Admin: 'Role.Admin',
  RegisteredProfessional: 'Role.RegisteredProfessional',
  AccreditedBody: 'Role.AccreditedBody',
  DmreAdmin: 'Role.DmreAdmin',
  Client: 'Role.Client'
} as const;

export type RoleKeys = keyof typeof Role;
export type RoleEnum = typeof Role[RoleKeys];
export const RoleValues = values(Role);

const RoleMap = {
  'Admin': 'Role.Admin',
  'DMRE Admin': 'Role.DmreAdmin',
  'Registered Professional': 'Role.RegisteredProfessional',
  'SANAS Accredited Body': 'Role.AccreditedBody',
  'Building Owner/Accounting Officer': 'Role.Client'
};

export const RoleSelect = RoleMap;
export const RoleIndexed = invert(RoleMap);

export default Role;