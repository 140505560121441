import { AxiosInstance } from 'axios';
import apiOf, { downloadFile, extractData, objectToQueryString } from '../shorthand';
import Building from '../types/building';

const building = (apiBase: AxiosInstance) => {
  const controller = 'building';
  return {
    upsert: apiOf.put<Building.Upsert.Request, Building.Upsert.Response>(apiBase, `${controller}`),
    upsertStep: apiOf.putBy<Building.Upsert.Request, Building.Upsert.Response, Building.Upsert.By>(apiBase, (step) => `${controller}/${step}`),
    getAll: apiOf.getByQueryString<Building.GetAll.Request, Building.GetAll.Response>(apiBase, `${controller}/all`),
    get: apiOf.getBy<Building.Get.Response>(apiBase, (id) => `${controller}/${id}`),
    delete: apiOf.deleteBy<Building.Delete.Response>(apiBase, (id) => `${controller}/${id}`),
    export: (request: Building.Export.Request) => downloadFile(apiBase.post<Building.Export.Response>(`${controller}/export`, request, { responseType: 'blob' })),
    exportAll: (filters: Building.ExportAll.Request) => downloadFile(apiBase.get<Building.ExportAll.Response>(`${controller}/export-all${objectToQueryString(filters)}`, { responseType: 'blob', timeout: 1000 * 60 * 10 })), // 10 minutes
    occupancyClasses: apiOf.get<Building.OccupancyClassification.Response>(apiBase, `${controller}/occupancy-classes`),
    checkForDuplicate: apiOf.post<Building.DuplicateCheck.Request, Building.DuplicateCheck.Response>(apiBase, `${controller}/has-duplicates`),
    getDuplicatesFor: apiOf.getByIdAndQueryString<Building.GetAll.Request, Building.GetAll.Response>(apiBase, (buildingId) => `${controller}/list-duplicates/${buildingId}`),
    bulkUpload: (request: Building.BulkUpload.Request) =>
      extractData(apiBase.post<Building.BulkUpload.Response>(`${controller}/bulk-upload?fileName=${encodeURIComponent(request.fileName)}`, request.file, { headers: { 'Content-Type': request.type } })),
    getShared: apiOf.getBy<Building.GetShared.Response>(apiBase, (id) => `${controller}/shared/${id}`),
    setActiveEpc: apiOf.getBy<Building.SetActiveEpc.Response, Building.SetActiveEpc.By>(apiBase, (ids) => `${controller}/${ids.buildingId}/set-active-epc/${ids.energyInfoId}`)
  };
};

export default building;
