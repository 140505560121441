import { AxiosInstance } from 'axios';
import apiOf from '../shorthand';
import type RegisteredProfessional from '../types/registered-professionals';

const registeredProfessional = (apiBase: AxiosInstance) => {
  const controller = 'registered-professional';
  return {
    upsert: apiOf.put<RegisteredProfessional.Upsert.Request, RegisteredProfessional.Upsert.Response>(apiBase, `${controller}`),
    getAll: apiOf.getByQueryString<RegisteredProfessional.GetAll.Request, RegisteredProfessional.GetAll.Response>(apiBase, `${controller}/all`),
    get: apiOf.getBy<RegisteredProfessional.Get.Response>(apiBase, (id) => `${controller}/${id}`),
    getOwn: apiOf.get<RegisteredProfessional.Get.Response>(apiBase, `${controller}`),
  };
};

export default registeredProfessional;
