import { AxiosInstance } from 'axios';
import apiOf from '../../shorthand';
import MappedEntities from '../../types/entity-management/mapped-entities';

const mappedEntities = (apiBase: AxiosInstance) => {
  const controller = 'entity-management/mapped-entities';
  return {
    list: apiOf.get<MappedEntities.List.Response>(apiBase, `${controller}`),
    getByEntityName: apiOf.getBy<MappedEntities.GetByEntityName.Response>(apiBase, (id) => `${controller}/${id}`)
  };
};

export default mappedEntities;
