import React from 'react';
import { refreshApi, refreshApiBase } from '@sanedi.epc/api';
import AuthContext from '@sanedi.epc/components/common/auth-context';

const LogoutPage = React.memo(() => {
    const [_token, setToken] = React.useContext(AuthContext);
    localStorage.removeItem('token');
    refreshApiBase();
    refreshApi();
    setToken(null);
    window.location.href = process.env.REACT_APP_LOGIN_URL!;
    return (null);
});

export default LogoutPage;