/* eslint-disable max-lines */
import { createTheme, ThemeOptions } from '@mui/material';

export enum ThemeColour {
  SanediGreen = '#398c27',
  SanediGrey = '#363635',
  SanediDarkGreen = '#2C6501',

  White = '#f7f7f7',
  Black = '#333333',
  Error = '#d32f2f'
}

export enum ThemeScreenSize {
  // TBD or fetched from MUI defaults
}

export enum ThemeFonts {
  Heading = '"Montserrat", sans-serif',
  Body = '"Open Sans", sans-serif',
}

const theme: (admin: boolean) => ThemeOptions = (admin: boolean) => createTheme({
  palette: {
    primary: {
      main: admin ? ThemeColour.SanediDarkGreen : ThemeColour.SanediGreen,
    },
    secondary: {
      main: admin ? ThemeColour.SanediGreen : ThemeColour.SanediDarkGreen,
    },
    background: {
      default: ThemeColour.White
    }
  },
  typography: {
    fontFamily: ThemeFonts.Body,
    fontWeightRegular: 300,
    fontWeightLight: 200,
    fontWeightMedium: 400,
    fontWeightBold: 600,
    h1: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
    h2: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
    h3: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
    h4: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
    h5: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
    h6: {
      fontFamily: ThemeFonts.Heading,
      fontWeight: 400,
    },
  },
  components: {
    MuiDrawer: {
      defaultProps: {
        variant: 'permanent'
      },
      styleOverrides: {
        paper: {
          borderRadius: 0,
          gap: 0,
          paddingTop: 12,
          boxSizing: 'border-box',
          background: admin ? ThemeColour.SanediGreen : ThemeColour.SanediDarkGreen,
          color: 'white',
          '& .MuiDivider-root': {
            borderColor: ThemeColour.SanediGrey,
          },
          '& .MuiSvgIcon-root': {
            color: 'white',
          }
        }
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        size: 'large'
      },
      styleOverrides: {
        sizeLarge: {
          borderRadius: '14px'
        },
        sizeMedium: {
          borderRadius: '12px',
          height: '36px',
          fontSize: '13px'
        },
        sizeSmall: {
          borderRadius: '10px',
          height: '30px',
          fontSize: '11px'
        }
      }
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        sizeSmall: {
          height: '40px',
          width: '40px'
        },
        sizeMedium: {
          height: '52px',
          width: '52px'
        },
        sizeLarge: {
          height: '64px',
          width: '64px'
        }
      }
    },
    MuiFormHelperText: {
      variants: [{
        props: {
          variant: 'filled'
        },
        style: {
          padding: 4,
          borderRadius: 4,
          backgroundColor: 'transparent',
          color: 'transparent',
          fontWeight: 400,
          opacity: 0.9,
          transition: 'all 0.2s ease-in-out',
          '&.Mui-error': {
            backgroundColor: ThemeColour.Error,
            color: 'white !important'
          }
        }
      }]
    },
    MuiPaper: {
      defaultProps: {
        elevation: 4
      },
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          padding: 24,
          gap: 24,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 8
        }
      }
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          flexDirection: 'row'
        }
      }
    },
    MuiStepper: {
      defaultProps: {
        orientation: 'vertical'
      }
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          '& > p': {
            fontWeight: 500
          }
        },
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: 8
        }
      }
    }
  }
});

export default theme;