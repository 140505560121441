import React from 'react';
import { Stack, Typography } from '@mui/material';

interface ToolbarHeadingProps {
  children: string;
}

const ToolbarHeading = React.memo(({ children }: ToolbarHeadingProps) => {
  return (
    <Stack direction={'row'} gap={2} justifyContent={'center'} alignItems={'center'}>
      <Stack sx={{ bgcolor: '#fff', height: '48px', width: '48px', borderRadius: '50%', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
        <img src='/eei-logo.png' height={48} width={48} alt="Energy Efficiency Initiative Logo"/>
      </Stack>
      <Typography variant="h6" noWrap component="div">{children}</Typography>
    </Stack>
  );
});

export default ToolbarHeading;