import { AxiosInstance } from 'axios';
import apiOf from '../shorthand';
import Public from '../types/public';

const publicController = (apiBase: AxiosInstance) => {
  const controller = 'public';
  return {
    enquiry: apiOf.post<Public.Enquiry.Request, Public.Enquiry.Response>(apiBase, `${controller}/enquiry`),
  };
};

export default publicController;
