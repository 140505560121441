import { AxiosInstance } from 'axios';
import apiOf from '../shorthand';
import Dictionary from '@sanedi.epc/types/common/dictionary';

const dashboardController = (apiBase: AxiosInstance) => {
  const controller = 'dashboard';
  return {
    get: apiOf.get<Dictionary<any>>(apiBase, `${controller}`),
  };
};

export default dashboardController;
