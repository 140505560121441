import React from 'react';
import AuthContext from '../common/auth-context';
import jwt_decode from 'jwt-decode';
import AuthTokenPayload from '@sanedi.epc/types/auth/auth-token-payload'

const useAuthToken = (): AuthTokenPayload | undefined => {
    const [token] = React.useContext(AuthContext);

    const tokenDetails: AuthTokenPayload | undefined = React.useMemo(() => token ? jwt_decode(token) : undefined, [token]);

    if (!token)
        return;

    return tokenDetails;
};

export default useAuthToken;