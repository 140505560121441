import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import PageLoader from './page-loader';
import ModalLoader from './modal-loader';

interface LoaderSuspenseProps {
  children: React.ReactNode;
  outlet?: boolean;
  modal?: boolean;
  allowAnonymous?: boolean
}

const LoaderSuspense = React.memo(({ children, outlet, modal, allowAnonymous: _allowAnonymous = false }: LoaderSuspenseProps) => {
  return (
    <>
      <Suspense fallback={modal ? <ModalLoader /> : <PageLoader />}>
        {children}
      </Suspense>
      {outlet ? <Outlet /> : null}
    </>);
});

export default LoaderSuspense;