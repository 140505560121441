import React from 'react';
import { Box, CircularProgress } from '@mui/material';

const PageLoader = React.memo(() =>
  (
    <Box sx={{
      display: 'flex',
      flex: 1,
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <CircularProgress />
    </Box>
  )
);

export default PageLoader;
