import axios from 'axios';
import auth from './controllers/auth';
import entityTemplate from './controllers/entity-management/entity-template';
import signOffs from './controllers/signOffs';
import mappedEntities from './controllers/entity-management/mapped-entities';
import certificates from './controllers/certificate';
import document from './controllers/documents-controller';
import building from './controllers/building';
import energyInformation from './controllers/energy-information';
import sanas from './controllers/sanas';
import post from './controllers/post-controller';
import dashboard from './controllers/dashboard-controller';
import publicController from './controllers/public-controller';
import registeredProfessionals from './controllers/registered-professionals';
import chat from './controllers/chat';

const apiPaths = {
  auth,
  mappedEntities,
  entityTemplate,
  signOffs,
  document,
  building,
  energyInformation,
  sanas,
  certificates,
  chat,
  post,
  dashboard,
  publicController,
  registeredProfessionals
  // add other controllers here
};

const setApiBase = (token?: string | null) => {
  let headers;
  if (token)
    headers = { Authorization: `Bearer ${token}` };
  else
    headers = {};

  return axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api/`,
    timeout: 30000,
    headers
  });
};

var apiBase = setApiBase(localStorage.getItem('token'));
export const refreshApiBase = (token?: string | null) => apiBase = setApiBase(token);

type ApiControllers = keyof typeof apiPaths;

const buildApi = () => {
  var controllers: { [controllerName in ApiControllers]: ReturnType<typeof apiPaths[controllerName]> } = {} as any;
  var tmp: any = controllers;
  for (let key in apiPaths)
    tmp[key] = apiPaths[key as ApiControllers](apiBase);
  controllers = tmp;

  return controllers;
};

/**
 * @example 
 * //* Usage with useQuery
 * //* GET
 * const { data } = useQuery(["auth"], api.auth.me);
 * 
 * //* POST/PUT/PATCH/DELETE etc...
 * const { data, mutateAsync: login } = useMutation(["auth"], api.auth.login);
 * login({ email: "", password: "" }).then(res => res.token);
 */
var api = buildApi();
export const refreshApi = () => api = buildApi();

export const initApi = (token?: string | null) => {
  refreshApiBase(token);
  return api;
};

export default api;
