import { AxiosInstance } from 'axios';
import Auth, { IsLoginSuccessResponse } from '../types/auth';
import apiOf, { extractData } from '../shorthand';
import { refreshApi, refreshApiBase } from '../api';

const auth = (apiBase: AxiosInstance) => {
  const controller = 'auth';
  return {
    login: (request: Auth.Login.Request) => 
      extractData(apiBase.post<Auth.Login.Response>(`${controller}/login`, request))
        .then((data) => { 
          if(IsLoginSuccessResponse(data)) {
            localStorage.setItem('token', data.token);
            refreshApiBase(data.token);
            refreshApi();
          }
          return data;
        }),
    register: apiOf.post<Auth.Register.Request, Auth.Register.Response>(apiBase, `${controller}/register`),
    verifyEmail: apiOf.post<Auth.VerifyEmail.Request, Auth.VerifyEmail.Response>(apiBase, `${controller}/verify-email`),
    requestReset: apiOf.post<Auth.RequestReset.Request, Auth.RequestReset.Response>(apiBase, `${controller}/request-reset`),
    passwordReset: apiOf.post<Auth.ResetPassword.Request, Auth.ResetPassword.Response>(apiBase, `${controller}/reset-password`),
    getAllUsers: apiOf.getByQueryString<Auth.GetAllUsers.Request, Auth.GetAllUsers.Response>(apiBase, `${controller}/users/all`),
    getUserNamesByIds: apiOf.post<Auth.UserNamesByIds.Request, Auth.UserNamesByIds.Response>(apiBase, `${controller}/users/by-ids`),
    deactivateUser: apiOf.deleteBy<Auth.DeactivateUser.Response>(apiBase, (id) => `${controller}/user/${id}`),
    invite: apiOf.post<Auth.Invite.Request, Auth.Invite.Response>(apiBase, `${controller}/invite`),
    bulkInvite: (request: Auth.BulkInvite.Request) => 
      extractData(apiBase.post<Auth.BulkInvite.Response>(`${controller}/bulk-invite?fileName=${encodeURIComponent(request.fileName)}`, request.file, { headers: { 'Content-Type': request.type } })),
  };
};

export default auth;